$(document).ready(function() {


    //sticki header

    $("#tool-bar-sticky").sticky({
        topSpacing: 0,
        zIndex: 9999
    });

    //current page url mainly for locale purposes
    var currentPageUrl = $(location).attr('href').split("/").splice(0, 5);
    var locale = currentPageUrl[3];

    //cookie law
    $.cookiepolicy({lang: locale});

    //get token on ajax
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },

        beforeSend: function() {
          $('#loader').show();
          $('#loader').addClass('show');

        },
        complete: function(){
         var loader = function(){
            $('#loader').removeClass('show');
            $('#loader').hide();
          }

          setTimeout(loader ,1420);
        },
    });

    //desktop menu
    $('.kategorie').on('click',function(event) {
      if($(this).children('.menu_level2').is(':animated')) {
        return false
      } else {
        if($(this).hasClass('slide_down')) {
          // $('.content-slide-down').animate({
          //   'margin-top':'0',
          // }, 250);
          $(this).children('.menu_level2').slideToggle(250);
            if($(this).has('div.menu_level2').length == 1) {
              $(this).toggleClass('slide_down');
            }
        } else {

          $('.kategorie').children('.menu_level2').slideUp(250);
          $('.kategorie').removeClass('slide_down');
          // $('.content-slide-down').animate({
          //   'margin-top':  $(this).children('.menu_level2').height()
          // }, 250);
          $(this).children('.menu_level2').slideToggle(250);
            if($(this).has('div.menu_level2').length == 1) {
              $(this).toggleClass('slide_down');
            }
        }
      }
    });

    //if info menu has li class active, make info active also
    if($(".info-list ul li").hasClass("active")) {
        $(".info-list").closest(".kategorie").addClass("kategorie--active");
    }


    //mobile menu
    $('#nav-icon').on('click', function() {
        $(this).toggleClass('nav-icon-active');
    });
    $('.menu-trigger').click(function(event) {
        var parents = $(this).parent();
        var menu = $(parents).children('.menu-show').first();
        event.preventDefault();
        if (menu.is(":visible")) {
            menu.slideUp(150);
            $(this).removeClass("open");
        } else {
            menu.slideDown(150);
            $(this).addClass("open");
        }
    });

  //slider home page

    $('.owl-carousel').owlCarousel({
        autoplay: true,
        smartSpeed: 1000,
        animateOut: 'fadeOut',
        stopOnHover : true,
        autoplayHoverPause: true,
        autoplayTimeout: 5000,
        singleItem : true,
        mouseDrag: false,
        touchDrag: false,
        loop:true,
        margin:0,
        nav:false,
        video:true,
        responsive: {
            0: {
                items: 1,
                margin: 0,
                center: 1
            }
        },
        onPlayVideo: function() {
            $('.owl-carousel').trigger('stop.owl.autoplay');
        }
    });


    // accordion
    $('.collapse').collapse();

    // input
    var inputs = document.querySelectorAll('#file');
    Array.prototype.forEach.call(inputs, function(input) {
        var label = input.previousElementSibling,
            labelVal = label.innerHTML;
        input.addEventListener('change', function(e) {
            var fileName = '';
            if (this.files && this.files.length > 1)
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
            else
                fileName = e.target.value.split('\\').pop();
            if (fileName)
                label.innerHTML = fileName;
            else
                label.innerHTML = labelVal;
            $('.img__submit').addClass('black');
        });
    });

    // image profile live preview
    function readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function(e) {
                $('#img__prev').attr('src', e.target.result);
            }
            reader.readAsDataURL(input.files[0]);
        }
    }

    $("#file").change(function() {
        readURL(this);
    });

    //AJAX GALLERY

    var galContainer,
        galCover,
        galThumbs,
        galThumbsLoaded = false,
        projectSlug,
        particleId,
        particleType,
        projectId
    ;

    //click on project photo counter
    $('.gallery').on('click','.all_photos_triger', function(e) {
        e.preventDefault();
        galContainer = $(this).closest('.gallery');
        galCover = galContainer.html();
        var projectId = galContainer.data('project-id');
        particleType = galContainer.data('particle-type');
        particleId = galContainer.data('particle-id');
        projectSlug = galContainer.data('project-slug');
        var counter = $(this).find('.photos_count').text().split(' ')[1];
        initSlideshow(particleId, particleType, counter, projectId);
    })

    $('.gallery').on('click','.close-ajax-gallery, .close-ajax-photo', function(e) {
        e.preventDefault();
        //console.log(galContainer, galCover);
        galContainer = $(this).parent().parent();
        galContainer = $(this).closest('.gallery');
        particleId = galContainer.data('particle-id');
        getGalCover(particleId);
    })

    function getGalCover(particleId) {
        galContainer = $('.gallery[data-particle-id="'+particleId+'"]');
        if(galContainer.hasClass('gallery-pages')) {
            url = '/api/v1/pageParticles/'+particleId+'/get_cover';
        } else {
            url = '/api/v1/projectParticle/'+particleId+'/get_cover';
        }
        $.ajax({
            url:url,
            type: 'GET',
            dataType: 'html',
            //cache: false,
            complete: function (jqXHR, textStatus) {
                // callback
            },
            success: function (data, textStatus, jqXHR) {
                //console.log(galContainer);
                galContainer.html(data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // error callback
                console.log(errorThrown);
            }
        });
    }

    function getGalThumbs(particleId) {
        //console.log('get gal thumbs');
        galContainer = $('.gallery[data-particle-id="'+particleId+'"]');
        if(galContainer.hasClass('gallery-pages')) {
            url = '/api/v1/pageParticles/'+particleId+'/get_thumbs';
        } else {
            url = '/api/v1/projectParticle/'+particleId+'/get_thumbs';
        }
        $.ajax({
            url:url,
            type: 'GET',
            dataType: 'html',
            //cache: false,
            complete: function (jqXHR, textStatus) {
                // callback
            },
            success: function (data, textStatus, jqXHR) {
                galThumbs = data;
                galContainer.html(galThumbs);
                //$('html, body').animate({
                    //scrollTop: galContainer.offset().top - (galContainer.height()/2)
                //}, 2000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // error callback
                console.log(errorThrown);
            }
        });
    }

    function initSlideshow(particleId, type, counter, projectId) {

        var whichPage = counter > 1 ? 2 : 1;
        galContainer = $('.gallery[data-particle-id="'+particleId+'"]');
        var url = '/api/v1/projectParticle/'+particleId+'/'+whichPage+'/get_photo';
        if (type=='external_photos' && !projectId) {
            url = '/api/v1/pageParticles/'+particleId+'/'+whichPage+'/get_photo';
        }

        $.ajax({
            url:url,
            type: 'GET',
            dataType: 'html',
            //cache: false,
            complete: function (jqXHR, textStatus) {
                // callback
            },
            success: function (data, textStatus, jqXHR) {
                galContainer.html(data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // error callback
                console.log(errorThrown);
            }
        });
    }

    $('.gallery').on('click','.show-image', function(e) {
        e.preventDefault();
        var particleId = $(this).closest('.gallery').data('particle-id');
        var page = $(this).data('page');

        galContainer = $('.gallery[data-particle-id="'+particleId+'"]');

        if(galContainer.hasClass('gallery-pages')) {
            url = '/api/v1/pageParticles/'+particleId+'/'+page+'/get_photo';
        } else {
            url = '/api/v1/projectParticle/'+particleId+'/'+page+'/get_photo';
        }
        //console.log(particleId,page);
        $.ajax({
            url:url,
            type: 'GET',
            dataType: 'html',
            complete: function (jqXHR, textStatus) {
                // callback
            },
            success: function (data, textStatus, jqXHR) {
                galContainer.html(data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // error callback
                console.log(errorThrown);
            }
        });

    })

    $('.gallery').on('click','.nav_prev,.nav_next', function(e) {
        e.preventDefault();
        var page = $(this).data('page');
        var particleId = $(this).closest('.gallery').data('particle-id');
        galContainer = $('.gallery[data-particle-id="'+particleId+'"]');
        if(galContainer.hasClass('gallery-pages')) {
            url = '/api/v1/pageParticles/'+particleId+'/'+page+'/get_photo';
        } else {
            url = '/api/v1/projectParticle/'+particleId+'/'+page+'/get_photo';
        }
        $.ajax({
            url:url,
            type: 'GET',
            dataType: 'html',
            complete: function (jqXHR, textStatus) {
                // callback
            },
            success: function (data, textStatus, jqXHR) {
                galContainer.html(data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // error callback
                console.log(errorThrown);
            }
        });

    })

    $('.gallery').on('click','.get-gal-thumbs', function(e) {
        e.preventDefault();
        galContainer = $(this).closest('.gallery');
        galType = galContainer.data('particle-type');
        particleId = galContainer.data('particle-id')
        getGalThumbs(particleId);
    })

    //END AJAX PHOTO CHANGER

    //RESERVATION MODULE START

    //declare variables
    var reservationId  = $('.dragable-grid').data('reservation-id'),
        reservationType  = $('.dragable-grid').data('reservation-type'),
        currentProjectId = 0,
        selectedPhotos = [],
        selectedPrints = [],
        photoList = [],
        filter = []
    ;

    //init functions if photo selector div exists
    if($("#photoSelector").length === 1) {
        getUniqueFilters();
        populateRotatePrintsOnInit();
    }


    //init gridstack
    var options = {
            //cellHeight: 250, //wysokość columny
            cellHeight: 200, //wysokość columny
            verticalMargin: 20, //margines
            disableResize: true, //wyłączona zmiana wielkości przez przeciąganie
            //disableDrag: true,  //wyłączona zmiana położenia
            width: 30, // liczba kolumn
            handle: '.drag-handle', // drag handle
            float : true,
            animate: true
        };
        $('.grid-stack').gridstack(options);

        var grid = $('.grid-stack').data('gridstack');

        //serialize and rotate on first enter
        selectProjectWithPrints(selectedPrints);

        //zmiana wilekosci klocka
        $('.resize_list').on('change', function(){
          var el = $(this).parent().parent();

          var elx = el.data('gs-x');
          var ely = el.data('gs-y');

          var width = $(this).children('.resize:selected').data('width');
          var height = $(this).children('.resize:selected').data('height');

          grid.resize(el, width, height);
        });

    //click to rotate
    $('body').on('click','.rotate', function(e) {
        e.preventDefault();
        var deg = $(this).parent().parent().attr('data-gs-rotate');
        console.log(deg);
        $(this).siblings('.rotate-img').find('.img-rotate').css('transform','rotate('+(deg - 90)+'deg)');
        if(deg == '-360') {var deg = 0;}
        $(this).parent().parent().attr('data-gs-rotate', parseInt(deg)-90);
    });

    //click to delete
    //@remove print and scan whole module to deselect classess
    $('body').on('click', '.remove', function(e){
        e.preventDefault();
        var el = $(this).parent().parent(),
            print_id = el.data('gs-print-id'),
            photo_id = el.data('gs-photo-id'),
            project_id = el.data('gs-project-id')
        ;
        $('div[data-print-id="'+print_id+'"]').removeClass('selected');
        grid.removeWidget(el, [true]);
        if (reservationType==='prints') {
            //console.log('usuwam z selected prints', el, selectedPrints);
            selectedPrints = selectedPrints.filter(function(obj) {
                return obj.print_id !== print_id;
            })
        }
        //remove from selected array also
        if (reservationType==='photos') {
            //remove from selected photos
            $('div.photo-wrapper[data-photo-id='+photo_id+']').removeClass('selected');
            selectedPhotos.splice({project_id: project_id, photo_id: photo_id}, 1);
            console.log('usuwam foto, teraz mam w selPh', selectedPhotos);
        }


        updateCounter();
    });

        var grid = $('.grid-stack').data('gridstack');

      $('.drag-handle, .remove, .rotate').on('click', function(event) {
        event.preventDefault();
      });

    //datepicker
    $('[data-provide="datepicker"]').datepicker({
        autoclose: true,
        format:  'yyyy-mm-dd',
        todayHighlight: true
    })

    //FILTERS EVENTS START

    //publication date range slider
    if($("#reservations-filters").length !== 0) {
        var bootstrapSlider = new Slider('#publicationDateFilter', {});
        bootstrapSlider.on('slideStop', function(slideArr) {
            updateDatesLabels(slideArr);
            //filterPhotos(true);
            getProjectPhotos(currentProjectId, reservationId);
        })
    }
    $('#technology-filter, #size-filter, #counter-filter').on('changed.bs.select', function (event, clickedIndex, newValue, oldValue) {
            //filterPhotos(true);
            getProjectPhotos(currentProjectId, reservationId);
    });

    $('#produced-filter').on('change', function(e) {
        //filterPhotos(true);
            getProjectPhotos(currentProjectId, reservationId);
    });

    //FILTERS EVENTS END

    //load photos under project
    $('body').on('click', 'a.project-photo-selector', function(e) {
        e.preventDefault();

        currentProjectId = $(this).data('project-id'); //set global current project

        //select link as active
        $('a.project-photo-selector').removeClass('active');
        $(this).addClass('active');
        getProjectPhotos(currentProjectId, reservationId);
    })

    function getProjectPhotos(projectId, reservationId) {
        var filterData = getFilterData();
        $.ajax({
            url: '/api/v1/projects/'+projectId+'/reservation/'+reservationId+'/prints',
            method: 'get',
            data: filterData
        }).done(function(response) {
            //clear old photo list
            $('.photo-list .photos').html('');
            //replace url
            //window.location.replace(url)

            //find if some photos are present in selected array
            var photos = response.data;
            if(photos.length == 0) {
                $('.no-results').fadeIn();
            } else {
                $('.no-results').fadeOut();
            }
            createPhotosList(photos);
            selectProjectWithPrints(selectedPrints);
            addSelectedClassToPhotos(selectedPrints);
            if(reservationType === 'photos') {
                addSelectedClassFromSelectedPhotos(selectedPhotos);
            }
        }).fail(function(error) {
            console.log(errror);
        });
    }

    function getFilterData() {
            //produced: encodeURIComponent('produced|not_produced'),
        var counterVal = $('#counter-filter').val(),
            sizeVal = $('#size-filter').val(),
            techVal = $('#technology-filter').val()
            pubVal = $('#publicationDateFilter').val().split(",")
            producedVal = $('#produced-filter').val()
        ;
        //sizeVal = sizeVal !== null ? sizeVal.join(',') : null;
        //techVal = techVal !== null ? techVal.join(',') : null;
        //counterVal = counterVal !== null ? counterVal.join('|') : null;

        var filter={
            counterVal: counterVal,
            sizes: sizeVal,
            techs: techVal,
            pubDateStart: pubVal[0],
            pubDateEnd: pubVal[1],
            produced: producedVal
        }
        return filter;
    }

    //select photo and add selected photo to array
    //select visually project also
    $('body').on('click', '.photo-list .photo', function(e) {
        e.stopPropagation();
        var photo_id = $(this).parent().data('photo-id');

        if($(this).parent().hasClass('selected')) {
            //remove photos prints from selecteds arrays
            var indexOf = selectedPhotos.indexOf({project_id: currentProjectId, photo_id: photo_id});
            selectedPhotos.splice(indexOf, 1);
            if (reservationType==='prints') {
                splicePhotoFromSelectedPrints(photo_id);
            }
            //remove class
            $(this).parent().removeClass('selected');
            if (reservationType === 'photos')
                removeWidgetPhoto(photo_id);
        } else {
            //add class
            $(this).parent().addClass('selected');
            //push photo
            selectedPhotos.push({project_id: currentProjectId, photo_id: photo_id });
            if (reservationType === 'photos')
                addWidgetPhoto(photo_id);
        }
        selectProjectWithPrints(selectedPrints);
        updateCounter();
    })

    $('#chosen-photo-filter').on('change', function() {
       var result = $(this).is(':checked');
       toggleChosenPhotos(result);
    })

    $('body').on('click', '.save-reservation, .ask-reservation', function(e) {
        e.preventDefault();


        if($('.grid-stack-item').length===0){
            alert(trans[locale].atLeastOne);
            return;
        }

        var url = $(this).attr('href'),
            mode = $(this).data('mode')
        ;

        var reservationPrints = [];
        var reservationId = $('.dragable-grid').attr('data-reservation-id');
        var startDate = $('.dragable-grid').attr('data-start-date');
        var endDate = $('.dragable-grid').attr('data-end-date');
        $.each($('.grid-stack-item'),function(index, value) {
            reservationPrints.push({
                reservation_id: reservationId,
                photos_id: $(this).attr('data-gs-photo-id'),
                print_id: $(this).attr('data-gs-print-id'),
                start_date: startDate,
                end_date: endDate,
                x: $(this).attr('data-gs-x'),
                y: $(this).attr('data-gs-y'),
                rotate: $(this).attr('data-gs-rotate'),
                returned: 0
            });
        });
        //validation, if photos => selected prints
        for (var i = 0, l = selectedPhotos.length; i < l; i++) {

            var v = selectedPhotos[i],
                printPhotoCorrespondence = false;
            //check if every photo has prints
            //@TODO use filter instead indexOf
            if (reservationPrints.indexOf({photos_id: v.photo_id}) != -1)
                printPhotoCorrespondence = true;
        }

        if(printPhotoCorrespondence === false && reservationType === 'prints') {
            if (confirm(trans[locale].confirmSelectedPhotosHasNoPrints))
                $.ajax({
                    url:url,
                    data: {prints: reservationPrints, mode: mode},
                    method: 'POST'
                }).done(function() {
                    alert(trans[locale].saved);
                }).fail(function(response) {
                    var apiError = response.responseJSON;
                    displayErrorMessage(apiError);
            });
        } else {
            $.ajax({
                url:url,
                data: {prints: reservationPrints, mode: mode},
                method: 'POST'
            }).done(function() {
                alert(trans[locale].saved);
            }).fail(function(response) {
                    var apiError = response.responseJSON;
                    displayErrorMessage(apiError);
            });
        }


    })

    //add class to project which has selected prints
    function selectProjectWithPrints() {
        var projectsIds = selectedPrints.map(function(obj) { return obj.project_id; });
        projectsIds.map(function(obj) {
            $(".project-photo-selector[data-project-id='"+obj+"']").addClass('hasPrints');
        });
    }

    //creates photo list arr and puts them into container div
    function createPhotosList(photos) {
        var photoIndex = -1,
            currentPhotoId
        ;
        photoList = [] //clear old photolist

        //create photoList arr
        photos.map(function(obj) {
            //console.log('photo map', obj);
            var currentPhoto = obj;
            var ap = currentPhoto.ap == 0 ? false : true;
            var pub_date =  currentPhoto.photo_publication_date;
            var seq = currentPhoto.sequence;
            var exhibition_counter = currentPhoto.exhibition_count;
            var book_counter = currentPhoto.book_count;
            var prize_counter = currentPhoto.prize_count;
            var photo_name = currentPhoto['photo_name_'+locale] || '-';
            var project_name = currentPhoto['project_name_'+locale] || '-';
            var ap_length = currentPhoto.ap_length;
            var non_ap_length = currentPhoto.non_ap_length;
            var prod_length = currentPhoto.prod_length;
            var non_prod_length = currentPhoto.non_prod_length;
            var status = currentPhoto.status;

            //here be photo
            if (currentPhotoId != currentPhoto.photo_id) {
                //count produced, not produced
                photoIndex++;
                currentPhotoId = currentPhoto.photo_id;

                //create the photo first and return index
                photoIndex = photoList.push({project_name: project_name, publication_date: pub_date, photo_id: currentPhotoId, photo_name: photo_name, catalogue_no: currentPhoto.catalogue_no, filename: currentPhoto.filename, prize_counter: prize_counter, book_counter: book_counter, exhibition_counter: exhibition_counter, prints: [], sizes:[], technologies:[], uniqueProduced: [], prod_length: prod_length, non_prod_length: non_prod_length}) - 1;

                if(reservationType === 'prints') {
                    photoList[photoIndex].prints.push({publication_date: pub_date, filename: currentPhoto.filename, ap: ap, print_id: currentPhoto.print_id, edition_name: currentPhoto.name, tech_name: currentPhoto['tech_name_'+locale], print_size: currentPhoto.ed_size, publication_date: pub_date, sequence: seq, ap_lenght: ap_length, non_ap_length: non_ap_length, status: status});
                    //push sizes to photo
                    photoList[photoIndex].sizes.push(currentPhoto.ed_size);
                    //push technologies to photo
                    photoList[photoIndex].technologies.push(currentPhoto['tech_name_'+locale]);
                }
            } else {
                //here be print
                photoList[photoIndex].prints.push({filename: currentPhoto.filename, ap:ap, print_id: currentPhoto.print_id, edition_name: currentPhoto.name, tech_name: currentPhoto['tech_name_'+locale], print_size: currentPhoto.ed_size, publication_date: pub_date, sequence: seq,ap_lenght: ap_length, non_ap_length: non_ap_length, status:status});
                //push sizes to photo
                photoList[photoIndex].sizes.push(currentPhoto.ed_size);
                //push technologies to photo
                photoList[photoIndex].technologies.push(currentPhoto['tech_name_'+locale]);
            }

            //console.log('zdjecie',photoList[photoIndex],'wyprodukowane', producedCounter, 'niewyprodu', notProducedCounter);

        })

        //check presence of produced and not produced prints
        //addProducedCountToPhotoArr();

        //console.log('415:createPhotosList js part', photoList);

        //create photos HTML
        $.each(photoList, function(index) {
            //add photo from array to dom
            var currentPhoto = photoList[index],
                printsLength,
                printsCounter = ''
            ;

            //get prints length by produced filter
            var producedVal = $('#produced-filter').val()
            if (producedVal === 'all') {
                printsLength = currentPhoto.non_prod_length + currentPhoto.prod_length;
            } else if (producedVal === 'produced') {
                printsLength = currentPhoto.prod_length;
            } else if (producedVal === 'not produced') {
                printsLength = currentPhoto.non_prod_length;
            }

            var uniqueSizes = unique(currentPhoto.sizes);
            //var uniqueProduced = unique(currentPhoto.produced);
            //check if null exists in sizes arr
            if(uniqueSizes.indexOf(null) != -1)
                var nullIndex = uniqueSizes.indexOf(null)
                uniqueSizes[nullIndex] = 'brakSize';
            var uniqueTechs = unique(currentPhoto.technologies);
            //check if null exists in sizes arr
            if(uniqueTechs.indexOf(null) != -1)
                var nullIndex = uniqueTechs.indexOf(null)
                uniqueTechs[nullIndex] = 'brakTech';
            //if reserv mode photos add print-counter
            if (reservationType === 'prints') {
                printsCounter = '<a href=# data-toggle="modal" data-target="#prints-modal" data-photo-id="'+currentPhoto.photo_id+'" class="printsLength" title="Ilość odbitek">'+currentPhoto.prints.length+'</a>';
            }

            var nameArr = currentPhoto.filename.split('.');
            var thumbname = nameArr[0]+'-x250.'+nameArr[1];
            var photoString = '<div class="col-1-of-5 photo-wrapper" '+
                'data-sizes="'+uniqueSizes+'" data-technologies="'+uniqueTechs+'" data-publication-date="'+currentPhoto.publication_date+'"'+'data-produced-prints="'+currentPhoto.uniqueProduced+'"'+
                ' data-exhibition-count="'+currentPhoto.exhibition_counter+'" data-prize-count="'+currentPhoto.prize_counter+'" data-book-count="'+currentPhoto.book_counter+'"'+
                ' data-photo-id="'+currentPhoto.photo_id+'"'+
                ' data-prod-length="'+currentPhoto.prod_length+'"'+
                ' data-non-prod-length="'+currentPhoto.non_prod_length+'"'+
                '>'+
                '<div class="photo photo-wraper-img-container" style="background-image: url(/images/photos/'+thumbname+')" >'+
                '</div>'+
                '<span class="photo-details">'+
                '<span>' +currentPhoto.photo_name+'</span><span>'+currentPhoto.catalogue_no+
                '</span></span>'+
                printsCounter+
                '</div>';

            $('.photo-list .photos').fadeOut(function() {
                $('.photo-list .photos').append(photoString);
            });

        });

            $('.photo-list .photos').fadeIn();
    }

    //make global prints in modal arr
    var printsInModal = [];

    //on show bootstrap modal, get prints for photo
    $(document).on('show.bs.modal','#prints-modal', function (e) {
        var button = $(e.relatedTarget),
            photo_id = button.data('photo-id')
        ;

        //find which photo appears in modal
        var pos = photoList.map(function(e) { return e.photo_id; }).indexOf(photo_id);

        var prints = photoList[pos].prints,
            filename = photoList[pos].filename,
            photo = photoList[pos]
        ;

        //flat selectedPrints arr for comparision
        var flatSelectedPrints = selectedPrints.map(function(obj) {
            return obj.print_id;
        });
        //console.log('printsy ktore sprawdzam w modalu', flatSelectedPrints);
        //console.log('photo w modalu', photo);

        //create table and insert it to modal body
        var modalPhotoDetails = '<div class="modal-photo-details">'+
            '<span class="label">'+trans[locale].photoname+'</span><span class="detail photo-name">'+photo.photo_name+'</span>'+
            '<span class="label">'+trans[locale].projectname+'</span><span class="detail project-name">'+photo.project_name+'</span>'+
            '<span class="label">'+trans[locale].catalogueno+'</span><span class="detail catalogue-no">'+photo.catalogue_no+'</span>'+
            '<span class="label">'+trans[locale].publicationdate+'</span><span class="detail publication-date">'+photo.publication_date+'</span>'+
        '</div>';
        var imgString = '<div class="modal-img"><img src="/images/photos/'+filename+'"></div>';
        var tableString = imgString+modalPhotoDetails+'<table data-photo-id="'+photo_id+'" class="table table-bordered"><thead><tr><th>'+trans[locale].edition+'</th><th>'+trans[locale].lp+'</th><th>'+trans[locale].size+'</th><th>'+trans[locale].technology+'</th><th>'+trans[locale].status+'</th><th>'+trans[locale].choose+'</th></tr></thead><tbody>';
        //generate tr
        $.each(prints, function(index, val) {
            var print = prints[index];
            //check if print exist in flat arr
            if(flatSelectedPrints.indexOf(print.print_id) != -1) {
                var checked = 'checked="checked"';
                printsInModal.push(print);
            }
            //console.log(print);

            var ap = print.ap === false ? '' : 'AP',
                cm = 'cm',
                printStatus
            ;
            var printLp = print.sequence;
            if(print.ap===false) printLp+='/'+print.non_ap_length;
            if(print.ap===true) printLp+='AP';

            print.photo_id = photo_id;

            if (print.status == 'produced') {
              printStatus = trans[locale].produced;
            } else if (print.status =='not produced') {
              printStatus = trans[locale].not_produced;
            } else {
              printStatus = trans[locale].other_status;
            }

            print.print_size = print.print_size === null ? 'brak' : print.print_size;
            print.print_size_data = print.print_size === 'brak' ? 'brakSize' : print.print_size;
            if (print.print_size == 'brak') cm = '';
            print.tech_name = print.tech_name === null ? 'brak' : print.tech_name;
            print.technology_data = print.tech_name === 'brak' ? 'brakTech' : print.tech_name;
            tableString += '<tr data-tech="'+print.technology_data+'" data-size="'+print.print_size_data+'" data-produced="'+print.status+'" data-photo-id="'+print.photo_id+'" data-print-id="'+print.print_id+'"><td>'+print.edition_name+'</td><td>'+printLp+'</td><td>'+print.print_size+' '+cm+'</td><td>'+print.tech_name+'</td><td>'+printStatus+'</td><td><input class="print-check" '+checked+' type="checkbox"></tr>';
        })
        tableString += '</tbody></table>'

        //console.log('stan prints in modal', printsInModal);
        // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        var modal = $(this);
        modal.find('.modal-body').html(tableString);

        filterInsideModal();
    })

    function filterInsideModal() {
        var filterStatus   = $('#produced-filter').val(),
            sizes     = $('#size-filter').val(),
            techs     = $('#technology-filter').val(),
            filterInModal  = [],
            result1 = false,
            result2 = false,
            result3 = false
        ;

        //filtrowanie statusem
        if (filterStatus == 'all') {
            filterInModal.push('produced', 'not produced');
        } else {
            filterInModal.push(filterStatus);
        }

        //filtrowanie rozmiarem
        if(sizes !== null) {
            for (var i = 0, len = sizes.length; i < len; i++) {
                filterInModal.push(sizes[i]);
            }
        } else {
            result2 = true;
        }

        //filtrowanie technologia
        if(techs !== null) {
            for (var i = 0, len = techs.length; i < len; i++) {
                filterInModal.push(techs[i]);
            }
        } else {
            result3 = true;
        }

        console.log('filtr w modalu', filter);

        $('.modal-body table tbody tr').hide().promise().done(function(){

            $('.modal-body table tbody tr').filter(function() {

                var produced = $(this).data('produced');
                result1 = filterInModal.indexOf(produced) != -1;


                var size = $(this).data('size');
                if(sizes !== null) {
                     result2 = filterInModal.indexOf(size) != -1;
                } else {
                    result2 = true;
                }

                var tech = $(this).data('tech');
                if(techs !== null) {
                     result3 = filterInModal.indexOf(tech) != -1;
                } else {
                    result3 = true;
                }


                return result1 && result2 && result3;
            }).fadeIn();
        });
    }



    //prevent modal hiding
    var letModalHide = false;
    $('#prints-modal').on('hide.bs.modal',function(e){
        if (letModalHide !== true) {
            e.preventDefault();
        }
        letModalHide = false;
    });

    //toggle prints inside modal
    $('#prints-modal').on('click', '.print-check', function() {
        var checked = $(this).is(':checked'),
            print_id = $(this).parent().parent().attr('data-print-id'),
            photo_id = $(this).parent().parent().attr('data-photo-id')
        ;

        var photo = photoList.filter(function(el) {
            return el.photo_id == photo_id;
        })
        photo = photo[0];

        console.log('znalazlem  foto', photo);

        var print = photo.prints.filter(function(el) {
            return el.print_id == print_id;
        })
        print = print[0];

        //console.log('znalazlem  print', print);

        if (checked === false) {
            removePrint(print);
        } else {
            addPrint(print);
        }
    })

    //modal cancel button handler
    $(".modalCancelBtn").on('click', function(e) {
        e.preventDefault();
        //clear modal selection
        printsInModal = [];
        //hide modal
        letModalHide = true;
        $(".modal").modal('hide');
    })

    //modal save button handler
    $("#modalSaveBtn").on('click', function(e) {
        e.preventDefault();
        var photo_id = $(this).closest('.modal').find('table').data('photo-id');
        //compare selected modal prints to global selected prints
        //if none, remove all prints
        if(printsInModal.length === 0) {
            splicePhotoFromSelectedPrints(photo_id);
            $('.photo-wrapper[data-photo-id="'+photo_id+'"]').removeClass('selected');
        } else {
            //find all the prints of photo
            var pos = photoList.map(function(e) { return e.photo_id; }).indexOf(photo_id);
            var photoPrints = photoList[pos].prints;
            //what to remove
            var toRemove = _.differenceBy(photoPrints, printsInModal, 'print_id');
            toRemove.map(function (el) {
                console.log('element,ktory usuwam', el);
                removeWidget(el);
                //console.log('przed usunieciem', selectedPrints);
                selectedPrints = selectedPrints.filter(function(obj) {
                    return obj.print_id !== el.print_id;
                })
                //console.log('po usunieciu', selectedPrints);
            });
            //console.log('to remove', toRemove, 'wynik', selectedPrints);
            //what to add
            printsInModal.map(function (el) {
                if($('.grid-stack-item[data-gs-print-id="'+el.print_id+'"]').length === 0){
                    //console.log('dodaje el', el);
                    selectedPrints.push(el);
                    var pos = photoPrints.map(function(e) { return e.print_id; }).indexOf(el.print_id);
                    addWidget(photoPrints[pos]);
                    $('.photo-wrapper[data-photo-id="'+el.photo_id+'"]').addClass('selected');
                }
            });
        }

        updateCounter();
        //reset printsInModal and hide modal
        printsInModal = [];
        letModalHide = true;
        $(".modal").modal('hide');
    })



    function addPrint(print) {
        //console.log('aktualnie dodawany print', print);
        printsInModal.push({print_id: print.print_id, photo_id: print.photo_id, project_id: currentProjectId, publication_date: print.publication_date});
    }

    function removePrint(print) {
        printsInModal = printsInModal.filter(function(obj) {
            //console.log('obiekt w filtrze',obj,'odbitka', print.print_id);
            return obj.print_id !== print.print_id;
        });
    }

    function addWidget(print) {
        console.log('dodaje printa',print);
        var calculated = {w:5, h:1};
        if (print.print_size !== 'brak') {
           calculated = calculateGridSize(print.print_size);
        }
        //console.log('wartosc po wykalkulowaniu', calculated.w);

        var widgetString ='<div class="grid-stack-item" data-gs-photo-id='+print.photo_id+' data-gs-print-id='+print.print_id+' data-gs-rotate="0" data-gs-x="0" data-gs-y="0" data-gs-width="'+calculated.w+'" data-gs-height="'+calculated.h+'" data-gs-auto-position="1" data-gs-publication-date="'+print.publication_date+'"><div class="grid-stack-item-content"><div class="rotate-img"><div class="relative"><div class="vertical-align"><img class="img-responsive img-rotate" src="/images/photos/'+print.filename+'"/> </div> </div> </div> <a href="#" class="action rotate"><img src="/images/assets/refresh.svg"></a> <a href="#" class="action remove"><img src="/images/assets/close.svg"></a> <a href="#" class="action drag-handle"><img src="/images/assets/hand.svg"></a> <div class="select">'+print.print_size+'</div>';
        widgetString += '</div> </div></div>';
        grid.addWidget(widgetString);
    }

    function addWidgetPhoto(photo_id) {
        //console.log('dodaje photo',photo_id);
        var calculated = {w:5, h:1};
        //find photo
        var currentPhoto = photoList.filter(function(el) {
            return el.photo_id === photo_id;
        });
        var image = currentPhoto[0].filename;

        var widgetString ='<div class="grid-stack-item" data-gs-photo-id='+photo_id+' data-gs-rotate="0" data-gs-x="0" data-gs-y="0" data-gs-width="'+calculated.w+'" data-gs-height="'+calculated.h+'" data-gs-auto-position="1"><div class="grid-stack-item-content"><div class="rotate-img"><div class="relative"><div class="vertical-align"><img class="img-responsive img-rotate" src="/images/photos/'+image+'"> </div> </div> </div> <a href="#" class="action rotate"><img src="/images/assets/refresh.svg"></a> <a href="#" class="action remove"><img src="/images/assets/close.svg"></a> <a href="#" class="action drag-handle"><img src="/images/assets/hand.svg"></a>';
        widgetString += '</div></div>';
        grid.addWidget(widgetString);
    }

    function removeWidget(print) {
        if($('.grid-stack-item[data-gs-print-id="'+print.print_id+'"]').length !== 0){
            grid.removeWidget($('div[data-gs-print-id='+print.print_id+']'));
        } else {
            console.log('chcesz usunac nieusuwalne?');
        }
    }

    function removeWidgetPhoto(photo_id) {
        //remove class if visible in tab
        grid.removeWidget($('div[data-gs-photo-id='+photo_id+']'));
    }


    function spliceSelectedPrints(print_id) {
        console.log('selectedPrints przed deselect', selectedPrints);
        var abandonedPrints = [];
        selectedPrints = selectedPrints.filter(function(obj) {
            if (obj.photo_id == photo_id) {
                abandonedPrints.push(obj);
            }
            return obj.photo_id != photo_id;
        });

        console.log('porzucone odbitki', abandonedPrints);
        abandonedPrints.map(function(obj) {
            removeWidget(obj);
        })
        abandonedPrints = [];
        console.log('po zdeselektowaniu zdjecia zostalo odbitek', selectedPrints);
        updateCounter();
    }

    function splicePhotoFromSelectedPrints(photo_id) {
        console.log('selectedPrints przed deselect', selectedPrints);
        var abandonedPrints = [];
        selectedPrints = selectedPrints.filter(function(obj) {
            if (obj.photo_id == photo_id) {
                abandonedPrints.push(obj);
            }
            return obj.photo_id != photo_id;
        });

        console.log('porzucone odbitki', abandonedPrints);
        abandonedPrints.map(function(obj) {
            removeWidget(obj);
        })
        abandonedPrints = [];
        console.log('po zdeselektowaniu zdjecia zostalo odbitek', selectedPrints);
        updateCounter();
    }

    /**
     * collects prints from html to @var array selectedPrints
     *
     * @uses updateCounter
     */
    function populateRotatePrintsOnInit() {
        $('.grid-stack-item').each(function(el){

            var print_id = $(this).data('gs-print-id'),
                photo_id = $(this).data('gs-photo-id'),
                project_id = $(this).data('gs-project-id'),
                publication_date = $(this).data('gs-publication-date'),
                rotate = parseInt($(this).attr('data-gs-rotate'))
            ;

            //rotate on init
            $(this).find('.img-rotate').css('transform', 'rotate(' + rotate + 'deg)');

            selectedPrints.push({photo_id: photo_id, print_id: print_id, project_id: project_id, publication_date: publication_date});
        });
        updateCounter();
        //console.log('selectedPrints na starcie', selectedPrints);
    }


    /**
     * updates counter div
     * @returns $('.grid-stack-item').length
     */
    function updateCounter() {
       $('.selectedCounter').html($('.grid-stack-item').length);
    }

    /**
     * gets unique photo ids from @var array selectedPrints
     * and adds selected class to .photo-wrapper divs
     */
    function addSelectedClassToPhotos(selectedPrints){
        var photosIds = [],
            currentPhotoId
        ;

        photoIds = selectedPrints.map(function(obj) {
            //populate selected photos arr
            if (currentPhotoId !== obj.photo_id) {
                currentPhotoId = obj.photo_id;
                selectedPhotos.push({project_id: obj.project_id, photo_id: obj.photo_id});
            }
            return obj.photo_id;
        });
        photoIds = photoIds.filter(function(v,i) { return photoIds.indexOf(v) == i; });

        //console.log('wybieram zdjecia, ktore maja odbitki', photoIds);

        photoIds.map(function(obj) {
            $(".photo-wrapper[data-photo-id='"+obj+"']").addClass('selected').addClass('active');
        })
    }

    function addSelectedClassFromSelectedPhotos(selectedPhotos) {
        selectedPhotos.map(function(obj) {
            $(".photo-wrapper[data-photo-id='"+obj.photo_id+"']").addClass('selected').addClass('active');
        })
    }

    function toggleChosenPhotos(state) {
        //hide non selected photos
        console.log('stan filtra wybrane foto', state);
        if (state === true) {
            $(".photo-wrapper").removeClass('is-animated').hide().promise().done(function() {
                $(".photo-wrapper.selected").addClass('is-animated').fadeIn();
            })
        } else {
            clearFilter();
        }
    }

    function clearFilter() {
        $('.photo-wrapper').removeClass('is-animated').hide().promise().done(function() {
            $('.photo-wrapper').addClass('is-animated').fadeIn();
        })
    }

    /**
     *  gets unique sizes and technologies
     *  for select boxes/filters
     */
    function getUniqueFilters() {

        $.get('/api/v1/distinctEditionDimensions').done(function(response) {
            var sizes = response.data;
            $('#size-filter').append($('<option>', {
                value: 'brakSize',
                text: trans[locale].brakString
            }));
            for (var i = 0, l = sizes.length; i < l; i++) {
                var v = sizes[i];
                $('#size-filter').append($('<option>', {
                    value: v.size,
                    text: v.size
                }));
            }
        });
        $.get('/api/v1/distinctTechnologies').done(function(response) {
            var techs = response.data;
            $('#technology-filter').append($('<option>', {
                value: 'brakTech',
                text: trans[locale].brakString
            }));
            for (var i = 0, l = techs.length; i < l; i++) {
                var v = techs[i];
                $('#technology-filter').append($('<option>', {
                    value: v.name,
                    text: v['name_'+locale]
                }));
            }
            //refresh select
            $('.selectpicker').selectpicker('refresh');
        });
    }

    function updateDatesLabels(slideArr) {
        var min = slideArr[0],
            max = slideArr[1];
        $('#minDate').text(min);
        $('#maxDate').text(max);
    }


    function displayErrorMessage(errors) {
        console.log('bledy walidacji w api', errors);
        if (errors.message.prints_error) {
            //push prints number to invalid prints
            var invalidPrints = [];
            for (k in errors.message.prints_error) {
                invalidPrints.push(k);
            }
            console.log('walidwe prints', invalidPrints);
            for (var i = 0, l = invalidPrints.length; i < l; i++) {
                var v = invalidPrints[i];
                $(".grid-stack-item[data-gs-print-id="+v+"]").addClass("invalidPrint");
            }

        }
        alert(trans[locale].conflictPrints)
    }

    function calculateGridSize(sizestring) {
        var dimArr     = sizestring.split('x'),
            width      = parseInt(dimArr[0]),
            height     = parseInt(dimArr[1]),
            calculated = {}
        ;

        calculated.h = 1;

        console.log('szerokosc odbitki', width);
        switch (true) {
            case width<=50:
                calculated.w=6; calculated.h=2;
                break;
            case width>50 && width<=75:
                calculated.w=8; calculated.h=2;
                break;
            case width>75 && width<105:
                calculated.w=10; calculated.h=2;
                break;
            case width>=105 && width<120:
                calculated.w=12; calculated.h=2;
                break;
            case width>=120 && width<150:
                calculated.w=14; calculated.h=3;
                break;
            case width>=150 && width<200:
                calculated.w=16;calculated.h=3;
                break;
            case width >=200:
                calculated.w=18; calculated.h=3;
        }

        //console.log('wykalkulowalem', calculated);

        return calculated;
    }

    function addProducedCountToPhotoArr() {
        var producedCounter=0;
        var notProducedCounter=0;
        for (var i = 0, len = photoList.length; i < len; i++) {
            for (var j = 0, len2 = photoList[i].prints.length; j < len2; j++) {
                if(photoList[i].prints[j].status=='produced') {producedCounter++;}
                if(photoList[i].prints[j].status=='not produced') {notProducedCounter++;}
            }
            if (producedCounter > 0) photoList[i].uniqueProduced.push('produced');
            if (notProducedCounter > 0) photoList[i].uniqueProduced.push('not produced');
            producedCounter = 0;
            notProducedCounter = 0;

        }
        //console.log(photoList, 'photolist po dodaniu produced counter');

    }

    //RESERVATION MODULE END

    //OTHER HELPER FUNCTIONS
    function unique(array){
        return array.filter(function(el, index, arr) {
            return index === arr.indexOf(el);
        });
    }


    var trans = {
        pl : {
            brakString : 'brak',
            noResults : 'Brak wyników',
            conflictPrints: 'Wybrane odbitki są zarezerwowane w innej rezerwacji. Przejdź do widoku aranżacji i zobacz, które odbitki powodują konflikt. Usuń je i spróbuj zapisać jeszcze raz',
            atLeastOne: 'Wybierz co najmniej jedną odbitkę/zdjęcie!',
            saved: 'Zapisane',
            confirmSelectedPhotosHasNoPrints : 'Niektóre zdjęcia nie posiadają wybranych odbitek w rezerwacji. Kontynuować?',
            lp : 'L.p.',
            edition : 'Edycja',
            size: 'Rozmiar',
            technology: 'Technologia',
            choose: 'Wybór',
            photoname: 'Nazwa zdjęcia',
            projectname: 'Nazwa projektu',
            publicationdate: 'Data publikacji',
            catalogueno: 'Numer katalogowy',
            status: 'Status',
            produced: 'Wyprodukowana',
            not_produced: 'Niewyprodukowana',
            other_status: 'Inny'
        },
        en : {
            brakString : 'none',
            noResults : 'No results',
            conflictPrints: 'Choosen prints are being reserved in other reservation. Switch to preview mode and remove them, before saving your reservation once again',
            atLeastOne: 'Select at least one print/photo!',
            saved: 'Saved',
            confirmSelectedPhotosHasNoPrints : 'Some of the selected photos have no selected prints. Do you continue to save the reservation?',
            lp : 'Ordinal',
            edition : 'Edition',
            size: 'Dimensions',
            technology: 'Technology',
            choose: 'Choose',
            photoname: 'Photo name',
            projectname: 'Project name',
            publicationdate: 'Publication date',
            catalogueno: 'Catalogue number',
            status: 'Status',
            produced: 'Produced',
            not_produced: 'Not produced',
            other_status: 'Other'
        }
    };

});
